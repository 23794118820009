<template lang="pug">
.youtube-container(ref="youtubeContainer")
  .youtube-container__video(:id="frameId")
  .youtube-container__cover(v-if="coverVisible" @click="playVideo")
    img.youtube-container__cover__image(:src="coverImgPath")
    img.youtube-container__cover__play(
      src="@/assets/admin/img/onboarding/dashboard/youtube-play.png"
    )
</template>

<script>
  export default {
    props: {
      videoId: {
        type: String,
        required: true,
      },
      autoPlay: {
        type: Boolean,
        required: false,
      },
      coverImgPath: {
        type: String,
        required: false,
      },
      captionLang: {
        type: String,
        required: false,
        default: 'hu',
      },
      captionSwitch: {
        type: Number,
        required: false,
        default: 0,
      },
    },
    data: () => ({
      coverVisible: false,
    }),
    computed: {
      frameId() {
        return `youtubeIframe-${this.videoId}`;
      },
    },
    watch: {
      coverImgPath() {
        this.coverVisible = !!this.coverImgPath;
      },
    },
    mounted() {
      this.loadYoutubeAPI();
      this.coverVisible = !!this.coverImgPath;
    },
    methods: {
      playVideo() {
        this.coverVisible = false;
        if (this.isYoutubeAPILoaded() && !!this.player?.playVideo) {
          this.player.playVideo();
        }
      },

      loadYoutubeAPI() {
        if (this.isYoutubeAPILoaded()) {
          this.initVideo();
          return;
        }

        // Load the YouTube API script
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        script.async = true;
        script.defer = true;
        this.$refs.youtubeContainer.appendChild(script);

        // Initialize the YouTube player when the API is ready
        window.onYouTubeIframeAPIReady = () => {
          this.initVideo();
        };
      },

      isYoutubeAPILoaded() {
        // Check if the YT object exists
        return typeof window.YT !== 'undefined' && window.YT.Player;
      },

      initVideo() {
        this.player = new window.YT.Player(this.frameId, {
          videoId: this.videoId,
          frameborder: 0,
          allow: 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
          allowfullscreen: true,
          playerVars: {
            autoplay: this.autoPlay ? 1 : 0,
            cc_lang_pref: this.captionLang,
            cc_load_policy: this.captionSwitch,
          },
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange,
          },
        });
      },

      onPlayerReady() {
        this.$emit('ready');
      },

      onPlayerStateChange(event) {
        // Check if the video state is 'PLAYING' (1) to detect when it starts
        if (event.data === window.YT.PlayerState.PLAYING) {
          this.$emit('playing');
        }
      },
    },
  };
</script>

<style lang="sass">
  .youtube-container
    overflow: hidden
    position: relative
    aspect-ratio: 16 / 9

    &__cover
      position: absolute
      z-index: 1
      width: 100%
      height: 100%
      background-size: cover
      top: 0
      left: 0
      overflow: hidden
      display: flex
      cursor: pointer

      &__play
       width: 6rem
       height: 6rem
       margin: auto
       z-index: 2
       filter: drop-shadow(0px 2.0395257472991943px 8.158102989196777px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2.0395257472991943px 5.098814487457275px rgba(0, 0, 0, 0.10))

      &__image
        width: 100%
        height: 100%
        position: absolute
        z-index: 0
        left: 0
        background-size: cover
        top: 0

    .youtube-container__video
      width: 100%
      height: 100%
</style>
